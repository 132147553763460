// 价格金额格式化 (github: vuex --> demo --> shopping-cart -->currency.js)

// const digitsRE = /(\d{3})(?=\d)/g
// // value: 传入的值; currency: 货币符号; decimals小数位数;
// function currency(value, currency, decimals) {
//   value = parseFloat(value)
//   if (!isFinite(value) || (!value && value !== 0)) return ''
//   currency = currency != null ? currency : '$'
//   decimals = decimals != null ? decimals : 2
//   const stringified = Math.abs(value).toFixed(decimals)
//   const _int = decimals
//     ? stringified.slice(0, -1 - decimals)
//     : stringified
//   const i = _int.length % 3
//   const head = i > 0
//     ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
//     : ''
//   const _float = decimals
//     ? stringified.slice(-1 - decimals)
//     : ''
//   const sign = value < 0 ? '-' : ''
//   return sign + currency + head
//         + _int.slice(i).replace(digitsRE, '$1,')
//         + _float
// }
// export default currency
export default function numberFormat(value) {
  let unit = ''
  let newValue = value
  const k = 10000
  const sizes = ['', '万', '亿', '万亿']
  let i
  if (value > k) {
    i = Math.floor(Math.log(value) / Math.log(k))
    // eslint-disable-next-line no-restricted-properties
    newValue = ((value / Math.pow(k, i))).toFixed(1)
    unit = sizes[i]
  }

  return newValue + unit
}
