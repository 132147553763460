<template>
  <div>
    <v-card-title class="align-start">
      <span :class="`${month === 'now'?'success':'warning'}--text`">{{ month === 'now'?'当前':'上期' }}时间段播放量：{{ sum() }}</span>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        ref="charts"
        :options="chartOptions"
        :series="chartData"
        height="318"
      ></vue-apex-charts>
    </v-card-text>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { kFormatter } from '@core/utils/filter'
import { getVuetify } from '@core/utils'
import store from '@/store'
import {
  onUnmounted, ref, onMounted, watch,
} from '@vue/composition-api/dist/vue-composition-api'
import storeModule from './statisticsStoreModule'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const WORK_STORE_MODULE_NAME = 'statistics-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const $vuetify = getVuetify()

    // const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'

    const chartOptions = ref({
      colors: [
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        offsetX: -15,
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 8,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ['1', '2', '3', '4', '5'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
          formatter: value => `${kFormatter(value, 0)}`,
        },
      },
      stroke: {
        width: [2, 2],
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          right: 0,
        },
      },
    })

    const charts = ref(null)
    const chartData = ref([
      {
        name: '播放量',
        data: [12, 12, 3232, 23434, 324],
      },
    ])

    const update = () => {
      chartData.value[0].data = props.data
      charts.value.updateOptions({
        xaxis: {
          categories: props.categories,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tickPlacement: 'on',
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
          },
        },
      })
    }

    onMounted(() => {
      update()
    })

    // eslint-disable-next-line no-eval
    const sum = () => eval(props.data.join('+'))

    watch(() => props.isActive, () => {
      if (props.isActive === true) {
        update()
      }
    })

    return {
      chartOptions,
      chartData,
      charts,
      update,
      sum,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
  data() {
    return {
      message: [],
    }
  },

  mounted() {
  },
}
</script>
