import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTotalLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/totalCount`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlatformLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/platform`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStatisticsLists(ctx, time) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/${time}Count`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChannelsLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/ranking`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountsLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/dayCount`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDownWeekLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/downWeek`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUpWeekLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/upWeek`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
