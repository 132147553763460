<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">流量统计</span>
      <v-spacer></v-spacer>
      <div
        style="width: 270px"
        class="mr-n5"
      >
        <v-tabs
          v-model="currentTab"
          class="demo-space-y mt-n5 mr-n5 mb-lg-10"
          right
        >
          <v-tab value="week">
            近七天
          </v-tab>
          <v-tab value="month">
            本月
          </v-tab>
          <v-tab value="year">
            本年
          </v-tab>
        </v-tabs>
      </div>
    </v-card-title>
    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="item in 3"
        :key="item"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              md="3"
              class="d-flex align-center"
            >
              <v-avatar
                size="44"
                color="primary"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiTrayArrowDown }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  下载视频数
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ downTotal[0] | numberFormat }}
                </h3>
              </div>
            </v-col>
            <v-col
              cols="6"
              md="3"
              class="d-flex align-center"
            >
              <v-avatar
                size="44"
                color="success"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiDatabaseArrowDownOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  下载视频总量
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ downTotal[1] | fileConversion }}
                </h3>
              </div>
            </v-col>
            <v-col
              cols="6"
              md="3"
              class="d-flex align-center"
            >
              <v-avatar
                size="44"
                color="warning"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiTrayArrowUp }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  上传视频数
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ upTotal[0] | numberFormat }}
                </h3>
              </div>
            </v-col>
            <v-col
              cols="6"
              md="3"
              class="d-flex align-center"
            >
              <v-avatar
                size="44"
                color="info"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiDatabaseArrowUpOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  上传视频总量
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ upTotal[1] | fileConversion }}
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <!--              <v-card flat>-->
        <!--                <v-card-text-->
        <!--                  class="pb-0"-->
        <!--                  v-text="tabItemContent"-->
        <!--                >-->
        <!--                </v-card-text>-->
        <!--              </v-card>-->
      </v-tab-item>
    </v-tabs-items>

    <!--    <v-card-subtitle class="mb-8 mt-n5">-->
    <!--      <span>（一周内）</span>-->
    <!--    </v-card-subtitle>-->
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiTrayArrowDown, mdiDatabaseArrowUpOutline, mdiTrendingUp, mdiDatabaseArrowDownOutline, mdiTrayArrowUp } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import fileConversion from '@core/libs/fileConversion'
import numberFormat from '@core/libs/numberFormat'
import storeModule from './statisticsStoreModule'

export default {
  filters: {
    fileConversion,
    numberFormat,
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'statistics-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    // const statisticsData = ref([
    //   {
    //     title: '下载视频数',
    //     total: '',
    //   },
    //   {
    //     title: '下载视频总量',
    //     total: '',
    //   },
    //   {
    //     title: '上传视频数',
    //     total: '',
    //   },
    //   {
    //     title: '上传视频总量',
    //     total: '',
    //   },
    // ])
    const downTotal = ref([])
    const upTotal = ref([])

    const showStatistics = time => {
      store.dispatch('statistics-list/fetchStatisticsLists', time)
        .then(response => {
          const { data } = response.data
          downTotal.value = data.downTotal
          upTotal.value = data.upTotal
        })
        .catch(error => {
          console.log(error)
        })
    }
    showStatistics('week')

    const resolveStatisticsIconVariation = item => {
      if (item === '下载视频数') return { icon: mdiDatabaseArrowDownOutline, color: 'primary' }
      if (item === '下载视频总量') return { icon: mdiTrayArrowDown, color: 'success' }
      if (item === '上传视频数') return { icon: mdiTrayArrowUp, color: 'warning' }
      if (item === '上传视频总量') return { icon: mdiDatabaseArrowUpOutline, color: 'info' }

      return { icon: mdiTrayArrowDown, color: 'success' }
    }
    const currentTab = ref(0)
    const tabItemContent = 'dscfddsfcdfcd'

    // eslint-disable-next-line no-shadow
    watch([currentTab], currentTab => {
      console.log(currentTab)
      if (currentTab[0] === 0) {
        console.log(0)
        showStatistics('week')
      } else if (currentTab[0] === 1) {
        console.log(1)
        showStatistics('month')
      } else {
        console.log(2)
        showStatistics('year')
      }
    })

    return {
      resolveStatisticsIconVariation,
      showStatistics,
      downTotal,
      upTotal,
      currentTab,
      tabItemContent,

      // icons
      icons: {
        mdiDatabaseArrowDownOutline,
        mdiTrendingUp,
        mdiTrayArrowDown,
        mdiTrayArrowUp,
        mdiDatabaseArrowUpOutline,
      },
    }
  },
}
</script>
