<template>
  <v-card>
    <v-card-title class="align-start">
      <span>频道排行</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mt-n6 mb-2">
      <span>（近七天）</span>
    </v-card-subtitle>

    <v-card-text style='min-height: 334px'>
      <v-list class="pb-0">
        <v-list-item
          v-for="(data,index) in channelList"
          :key="data.videoNames"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4':''}`"
        >
          <v-avatar
            :color="data.color"
            size="40"
            :class="`v-avatar-light-bg ${data.color}--text font-weight-medium me-3`"
          >
            <span class="text-base">{{ data.videoNames?data.videoNames.slice(0,1):'&#32;' }}</span>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold">
                <span class="text--primary text-base me-1">{{ data.videoSize | fileConversion }}</span>

                <!--                <v-icon-->
                <!--                  size="20"-->
                <!--                  :color="data.change.charAt(0) === '+' ? 'success':'error'"-->
                <!--                >-->
                <!--                  {{ data.change.charAt(0) === '+' ? icons.mdiChevronUp: icons.mdiChevronDown }}-->
                <!--                </v-icon>-->

                <!--                <span :class="`text-xs ${data.change.charAt(0) === '+' ? 'success&#45;&#45;text':'error&#45;&#45;text'}`">{{ data.change.slice(1) }}</span>-->
              </div>

              <v-list-item-subtitle class="text-xs">
                {{ data.videoNames }}
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
              <h4 class="font-weight-semibold">
                {{ data.videoCount }}
              </h4>
              <span class="text-xs">视频量</span>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import store from '@/store'
import storeModule from '@/views/dashboards/statistics/statisticsStoreModule'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import fileConversion from '@core/libs/fileConversion'

export default {
  filters: {
    fileConversion,
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'statistics-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const channelList = ref([])
    const colorList = ref(['success', 'error', 'warning', 'secondary', 'secondary'])
    const salesByCountries = [
      {
        videoCount: '0',
        videoNames: '一',
        change: '+25.8%',
        videoSize: '0',
        color: 'success',
      },
      {
        videoCount: '0',
        videoNames: '二',
        videoSize: '0',
        color: 'error',
      },
      {
        videoCount: '0',
        videoNames: '三',
        videoSize: '0',
        color: 'warning',
      },
      {
        videoCount: '0',
        videoNames: '四',
        videoSize: '0',
        color: 'secondary',
      },
      {
        videoCount: '0',
        videoNames: '五',
        videoSize: '0',
        color: 'error',
      },
    ]

    const showChannels = () => {
      store.dispatch('statistics-list/fetchChannelsLists')
        .then(response => {
          const { data } = response.data
          if (data.length) {
            channelList.value = data
            channelList.value.forEach((it, index) => {
              // eslint-disable-next-line no-param-reassign
              it.color = colorList.value[index]
            })
          } else {
            channelList.value = salesByCountries
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    showChannels()

    return {
      showChannels,
      channelList,
      colorList,
      salesByCountries,
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>
